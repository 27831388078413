<template>
<b-card v-if="companies" no-body class="card-company-table">
    <b-table :items="companies" responsive :fields="fields" class="mb-0">

        <!-- Id -->
        <template #cell(id)="data">
            <span class="font-weight-bolder mb-12">#{{data.index+1 }}</span>
        </template>

        <!-- CreatedAt Time -->
        <template #cell(createdAt)="data">
            <span class="font-weight-bolder mb-12">{{
            data.item.createdAt | moment("DD.MM.YYYY hh:mm")
          }}</span>
        </template>

        <!-- Status -->
        <template #cell(isActive)="data">
            <b-badge pill :variant="data.item.isActive?'light-success':'light-danger'">
                {{data.item.isActive?'AKTİF':'PASİF'}}
            </b-badge>
        </template>

        <!-- Branch Count -->
        <template #cell(branches)="data">
            <div class="d-flex flex-column">
                <span class="font-weight-bolder mb-25">{{
            data.item.branches.length
          }} Aktif</span>
                <span class="font-small-2 text-muted text-nowrap">Şube</span>
            </div>
        </template>

        <!-- Actions -->
        <template #cell(actions)="data">
            <div class="d-flex flex-column">
                <b-dropdown class="mx-1" right text="İşlemler" variant="flat-primary">
                    <b-dropdown-item variant="flat-primary" @click="getCompanyModule(data.item)">
                        <feather-icon icon="ArchiveIcon" class="mr-50" />
                        <span class="align-middle">Paket İşlemleri</span>
                    </b-dropdown-item>
                </b-dropdown>
            </div>
        </template>
    </b-table>

    <!--Open Add Company-->
    <b-sidebar sidebar-class="sidebar-lg" bg-variant="white" shadow backdrop no-header right v-model="addCompanyPanel">
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">Yeni Firma Ekle</h5>
                <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
            </div>

            <!-- Body -->
            <b-form class="p-2" @submit.prevent>
                <b-form-group label="Yetkili Kullanıcı ?" label-for="vue-select">
                    <v-select id="vue-select" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :reduce="(user) => user.id" :options="companyUsers" v-model="addCompanyRequest.adminUserId" :getOptionLabel="option => option.firstName+' '+option.lastName" />
                </b-form-group>

                <b-form-group label="Frima Adı" label-for="name">
                    <b-form-input name="firstName" id="name" type="text" v-model="addCompanyRequest.name" />
                </b-form-group>

                <b-form-group label="Firma Açıklaması" label-for="surname">
                    <b-form-input name="lastName" id="surname" type="text" v-model="addCompanyRequest.definition" />
                </b-form-group>

                <b-form-group label="Firma Adresi" label-for="code">
                    <b-form-input id="code" type="text" v-model="addCompanyRequest.address" />
                </b-form-group>

                <b-form-group label="Firma Web Sitesi" label-for="code">
                    <b-form-input id="code" type="text" v-model="addCompanyRequest.webSite" />
                </b-form-group>

                <b-form-group label="Firma Logosu" label-for="password">
                    <b-form-input id="password" type="text" v-model="addCompanyRequest.logoBase64" />
                </b-form-group>

                <!-- Form Actions -->
                <div class="d-flex mt-2">
                    <b-button variant="primary" class="mr-2" type="submit" @click="addCompany">
                        Ekle
                    </b-button>
                    <b-button variant="outline-secondary" @click="hide">
                        Vazgeç
                    </b-button>
                </div>
            </b-form>
        </template>

    </b-sidebar>

    <b-sidebar sidebar-class="sidebar-lg" bg-variant="white" width="50%" shadow backdrop no-header right v-model="packageInfoPanel">
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">Firmaya Ait Paketler</h5>
                <feather-icon class="ml-1 cursor-pointer" icon="PlusIcon" size="16" @click="addModulePanel=true" />
                <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
            </div>
            <app-collapse accordion type="margin">
                <app-collapse-item :title="pg.name" v-for="pg in packageComponent" :key="pg.refId">
                    <b-row class="kb-search-content-info match-height">
                        <b-col md="2" sm="6" class="kb-search-content" v-for="element in pg.modules" :key="element.refId">
                            <b-card no-body>
                                <b-card-body class="d-flex justify-content-between align-items-center">
                                    <div class="truncate">
                                        <h2 class="mb-25 font-weight-bolder">
                                            <span class="text-primary">{{element.limit}}</span>
                                        </h2>
                                        <span>{{element.name}}</span>
                                    </div>
                                </b-card-body>
                            </b-card>
                        </b-col>
                    </b-row>
                </app-collapse-item>
            </app-collapse>
            <!-- Body -->

        </template>
    </b-sidebar>

    <b-modal title="Yeni Paket Tanımla" ok-title="Ekle" ok-only cancel-variant="outline-secondary" v-model="addModulePanel" @ok="addCompanyPackage">
        <b-form>
            <b-form-group label="Eklemek İstediğiniz Paket" label-for="vue-select">
                <v-select id="vue-select" v-model="addPackageRequest.packageId" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :reduce="(pg) => pg.id" label="name" :options="packages" />
            </b-form-group>
            <b-form-group label="Başlangıç Tarihi" label-for="name">
                <flat-pickr v-model="addPackageRequest.startDate" class="form-control" :config="{ enableTime: true,time_24hr:true,dateFormat: 'd-m-Y H:i',locale:'tr'}" />
            </b-form-group>
            <b-form-group label="Bitiş Tarihi" label-for="name">
                <flat-pickr v-model="addPackageRequest.endDate" class="form-control" :config="{ enableTime: true,time_24hr:true,dateFormat: 'd-m-Y H:i',locale:'tr'}" />
            </b-form-group>
        </b-form>
    </b-modal>
</b-card>
</template>

<script>
import {
    BTable,
    BAvatar,
    BImg,
    BBadge,
    BSidebar,
    BTooltip,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BMediaBody,
    BMedia,
    BMediaAside,
    BFormCheckbox,
    BInputGroupPrepend,
    BInputGroup,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BCard,
    BCardBody,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
export default {
    components: {
        flatPickr,
        AppCollapse,
        AppCollapseItem,
        ToastificationContent,
        vSelect,
        BCard,
        BTable,
        BAvatar,
        BImg,
        BBadge,
        BSidebar,
        BTooltip,
        BForm,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BButton,
        BMediaBody,
        BMedia,
        BMediaAside,
        BFormCheckbox,
        BInputGroupPrepend,
        BInputGroup,
        BDropdown,
        BDropdownItem,
        BRow,
        BCol,
        BCard,
        BCardBody,
    },
    data() {
        return {
            fields: [
                { key: 'id', label: 'Ref' },
                { key: 'createdAt', label: 'Kayıt Tarihi' },
                { key: 'isActive', label: 'Durum' },
                { key: 'name', label: 'Firma Adı' },
                { key: 'definition', label: 'Firma Açıklaması' },
                { key: 'address', label: 'Firma Adresi' },
                { key: 'webSite', label: 'Firma Web Sitesi' },
                { key: 'apiKey', label: 'Firma Api Key' },
                { key: 'branches', label: 'Şube Sayısı' },
                { key: 'actions', label: 'İşlemler' },
            ],
            companies: [],
            companyUsers: [],
            addCompanyPanel: false,
            addCompanyRequest: {
                adminUserId: 0,
                name: "",
                definition: "",
                address: "",
                webSite: "",
                logoBase64: ""
            },
            packages: [],
            selectedCompany: {},
            packageComponent: {},
            packageInfoPanel: false,
            addModulePanel: false,
            addPackageRequest: {
                packageId: '',
                startDate: '',
                endDate: ''
            }
        }
    },
    created() {
        this.getData();
        this.getCompanyUser();
        this.getPackage();
    },
    mounted() {
        this.$root.$on('AddButtonClick', () => {
            this.addCompanyPanel = true;
        });
    },
    methods: {
        addCompanyPackage() {
            this.addPackageRequest.companyId = this.selectedCompany.id;
            var request = this.addPackageRequest;
            this.$http.post("Admin/Package/AddCompanyPackage", request).then((data) => {
                    this.getCompanyModule(this.selectedCompany);
                    this.addModulePanel = false;
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'İşlem',
                            icon: 'BellIcon',
                            text: "İşlem Başarılı Paket Eklendi",
                            variant: 'success'
                        },
                    });
                })
                .catch((error) => {
                    //console.log(error.response.data)
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Hata',
                            icon: 'BellIcon',
                            text: error.response.data.error.message,
                            variant: 'danger'
                        },
                    });
                });
        },
        async getPackage() {
            var packages = await this.$http.get("Admin/Package/Packages");
            this.packages = packages.data.data;
        },
        async getData() {
            var company = await this.$http.get("Admin/Companies");
            this.companies = company.data.data;
        },
        async getCompanyUser() {
            var users = await this.$http.get("Admin/Users");
            this.companyUsers = users.data.data;
        },
        async getCompanyModule(item) {
            var users = await this.$http.get("admin/Package/CompanyPackage/" + item.id);
            this.selectedCompany = item;
            this.packageComponent = users.data.data;
            this.packageInfoPanel = true;
        },
        addCompany() {
            var request = this.addCompanyRequest;
            this.$http.post("Admin/AddCompany", request).then((data) => {
                    this.getData();
                    this.addCompanyPanel = false;
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'İşlem',
                            icon: 'BellIcon',
                            text: "İşlem Başarılı Firma Oluşturuldu",
                            variant: 'success'
                        },
                    });
                })
                .catch((error) => {
                    //console.log(error.response.data)
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Hata',
                            icon: 'BellIcon',
                            text: error.response.data.error.message,
                            variant: 'danger'
                        },
                    });
                });
        }
    }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
    .dark-layout & {
        background: $theme-dark-body-bg !important;
    }
}
</style>
